<template>
  <div class="layout-simple">
    <BlockLayoutHeaderSimple
      :promo-link="campaignData?.landingPageUrl"
      :promo-link-text="campaignData?.landingpageButtonText"
    />

    <suspense>
      <slot />
    </suspense>
  </div>
</template>

<script setup>
const campaignData = inject('campaignData');
</script>
